import { hideAllResult } from "./convert";

const hideAllTabs = function () {
  $("#operation").hide();
  //   $("#results").hide();
};

export const tabs = function () {
  console.log("loading tabs...");
  hideAllTabs();

  const tabs = document.getElementById("tabs").children[0].children;
  // console.log(tabs);

  for (let i = 0; i < tabs.length; i++) {
    // console.log(tabs[i]);

    tabs[i].addEventListener("click", (ev) => {
      ev.preventDefault();
      // console.log('tab clicked ',tabs[i]);

      const currentTab = document.querySelector(".tabs > ul > li.is-active");
      currentTab.classList.remove("is-active");
      tabs[i].classList.add("is-active");

      // console.log(tabs[i].children[0].children[1].textContent);

      //> getting selected tab name
      const selectedTabName =
        tabs[i].children[0].children[1].getAttribute("data-value");

      //> get current tabname
      const currentTabname =
        currentTab.children[0].children[1].getAttribute("data-value");

      // console.log('selected tab : '+selectedTabName+" --- current tab : "+currentTabname);

      //> if same tab selected , exit from function
      if (selectedTabName == currentTabname) {
        console.log("On the same tab ,no further action");
        return;
      }

      //> if here , means tabs selected is different

      $("#" + currentTabname).hide();

      $("#" + selectedTabName).show();
    });
  }
};

const pattern = {
  binary: /^([01]?)+$/,
  decimal: /^([0-9]?)+$/,
  octal: /^([0-7]?)+$/,
  hexadecimal: /^([0-9A-Fa-f]?)+$/,
};

export const selectorID = {
  FROM: "#fromSelect",
  CONVERT_TO: "#toSelect",
};

const inputID = {
  INPUT_MODE: "#input-mode",
};

export const initInputMode = function () {
  const inputMode = document.querySelector(inputID.INPUT_MODE);
  let currentInputValue = "";

  //> checking and listen to dropdown [from]

  const fromSelector = document.querySelector(selectorID.FROM);
  const toSelector = document.querySelector(selectorID.CONVERT_TO);

  fromSelector.addEventListener("change", (ev) => {
    ev.preventDefault();
    hideAllResult();
    inputMode.setAttribute("data-input-mode", fromSelector.value);
    inputMode.setAttribute("placeholder", fromSelector.value);
    inputMode.setAttribute("pattern", pattern[fromSelector.value]);
    currentInputValue = "";
    inputMode.value = "";
  });

  toSelector.addEventListener("change", (ev) => {
    ev.preventDefault();
    hideAllResult();
  });

  inputMode.addEventListener("input", (ev) => {
    // console.log("-------------func-----------------");
    ev.preventDefault();
    //>validate input to a given mode

    const p = pattern[fromSelector.value];
    let value = inputMode.value.trim();
    // console.log("Rule: " + fromSelector.value + " regex:" + p);
    // console.log("value :" + value);

    if (p instanceof RegExp) {
      //   console.log("p is valid expression");

      if (p.test(value)) {
        currentInputValue = value;
        // inputMode.value = currentInputValue;
      } else {
        // console.log("value does not meet the requirements");
        inputMode.value = currentInputValue;
      }
    } else {
      //   console.log("p is not valid expression");
      inputMode.value = currentInputValue;
    }
    // console.log("----------------------------------");
  });
};
