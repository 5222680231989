//> this file is resposible to manage convert functionality from binary to decimal

//////////////////////////> convert button on click event

export const convert = (value) => {
  if (isEmptyValue(value)) {
    getTableHeadRow();
    getTableBaseRow();
    getTableMultipleRow();
    document.querySelector(answerBox.query.result).innerHTML = "";
    return false;
  }
  showTableHeadRowValue(value);
  showTableBodyRowBase(value);
  showTableBodyRowMultiple(value);
  return true;
};

//////////////////////////< end convert button on click event

//> for head row variables

const headRow = {
  _id: "convertTableHeadRow",
  style_th: "has-text-centered is-size-5",
  style_th_selected: "has-text-centered is-size-5 has-text-info",
};

//< for head row variables

//> for base row variables
const baseRow = {
  _id: "convertTableBodyBase",
  template_id: "convertTableBodyBaseTemplate",
  base_number: "2",
  style_td:
    "is-table-body-row is-table-body-row title is-table-title is-size-5 has-text-centered",
  style_select_base: {
    normal: "base is-size-4 has-text-weight-bold",
    selected: "base is-size-4 has-text-weight-bold has-text-info",
  },
  style_select_power: {
    normal: "power is-size-6 has-text-weight-bold",
    select: "power is-size-6 has-text-weight-bold has-text-info",
  },
};
//< end forbase row variables

//> for mulple row variables

const multipleRow = {
  _id: "convertTaleBodyMultiple",
  template_id: "convertTaleBodyMultipleTemplate",
  query: {
    onlySpanHasValue: "#convertTaleBodyMultiple>td>span.has-text-info",
  },
  td: {
    style_default: "has-text-centered is-size-5",
    span: {
      style_default: "is-size-5 has-text-weight-bold",
      style_selected: "has-text-info is-size-5 has-text-weight-bold",
    },
  },
};

//< end for multiple row variables

//> for answer box variables

const answerBox = {
  query: {
    result: ".answer-box>.subtitle",
  },
};

const STRING_BINARY = "binary";

////////////////////////> Table head Row eg 1101001010
//*helper function

const getTableHeadRow = () => {
  const tr = document.getElementById(headRow._id);
  tr.innerHTML = "";
  return tr;
};

const createTableHeadTH = (isSelect = false) => {
  const th = document.createElement("th");
  if (isSelect) {
    th.setAttribute("class", headRow.style_th_selected);
  } else {
    th.setAttribute("class", headRow.style_th);
  }
  return th;
};

const createTableHeadTH_abbr = (value) => {
  const abbr = document.createElement("abbr");
  abbr.setAttribute("title", STRING_BINARY);
  abbr.innerText = value;
  return abbr;
};

//*main function
const showTableHeadRowValue = function (value) {
  const tr = getTableHeadRow();

  for (let i = 0; i < value.length; i++) {
    let th = createTableHeadTH();

    if (value.charAt(i) == "1") {
      th = createTableHeadTH(true);
    }
    const abbr = createTableHeadTH_abbr(value.charAt(i));

    th.appendChild(abbr);
    tr.appendChild(th);
  }
};

//////////////////////////////// > Table Base Row eg 2^4

const getTableBaseRow = () => {
  const tr = document.getElementById(baseRow._id);
  tr.innerHTML = "";
  return tr;
};

const createTableBaseRowTD = () => {
  const td = document.createElement("td");
  td.setAttribute("class", baseRow.style_td);
  // td.innerText = STRING_BASE_2;
  return td;
};

const createTableBodyRowSpanPower = function (value, isSelect = false) {
  const span = document.createElement("span");
  if (isSelect) {
    span.setAttribute("class", baseRow.style_select_power.select);
  } else {
    span.setAttribute("class", baseRow.style_select_power.normal);
  }
  span.innerText = value;
  return span;
};

const createTableBodyRowSpanBase = function (isSelect = false) {
  const span = document.createElement("span");
  if (isSelect) {
    span.setAttribute("class", baseRow.style_select_base.selected);
  } else {
    span.setAttribute("class", baseRow.style_select_base.normal);
  }
  span.innerText = "2";
  return span;
};
//* main function
const showTableBodyRowBase = function (value) {
  const tr = getTableBaseRow();
  let power = parseInt(value.length) - 1;
  for (let i = 0; i < value.length; i++) {
    const td = createTableBaseRowTD();

    if (value.charAt(i) == "1") {
      td.appendChild(createTableBodyRowSpanBase(true));
      td.appendChild(createTableBodyRowSpanPower(power, true));
    } else {
      td.appendChild(createTableBodyRowSpanBase());
      td.appendChild(createTableBodyRowSpanPower(power));
    }

    tr.appendChild(td);
    power--;
  }
};

////////////////////////////////////////< end of table base row eg: 2^5

//////////////////////////> table multiple row eg 2  4, 6, 8, etc

const getTableMultipleRow = () => {
  const tr = document.getElementById(multipleRow._id);
  tr.innerHTML = "";
  return tr;
};

const createTableBodyRowMultipleTD = (value = "", isSelect = false) => {
  //  <td class="has-text-centered is-size-5">
  //    <span class="has-text-info is-size-5 has-text-weight-bold">3</span>
  //    <span class="has-text-info is-size-5 has-text-weight-bold">3</span>
  //  </td>;

  const td = document.createElement("td");
  td.setAttribute("class", multipleRow.td.style_default);

  const span = document.createElement("span");
  span.innerText = value;
  if (isSelect) {
    span.setAttribute("class", multipleRow.td.span.style_selected);
  } else {
    span.setAttribute("class", multipleRow.td.span.style_default);
  }

  td.appendChild(span);
  return td;
};

const showTableBodyRowMultiple = (value) => {
  const tr = getTableMultipleRow();
  let power = parseInt(value.length) - 1;
  for (let i = 0; i < value.length; i++) {
    const result = Math.pow(2, power);
    let td = null;
    if (value.charAt(i) == "1") {
      td = createTableBodyRowMultipleTD(result, true);
    } else {
      td = createTableBodyRowMultipleTD("0");
    }
    tr.appendChild(td);
    power--;
  }

  //>> getting td with value and adding them
  const trWithValue = document.querySelectorAll(
    multipleRow.query.onlySpanHasValue
  );
  let sumNumbers = null;
  let sum = 0;
  for (let i = 0; i < trWithValue.length; i++) {
    value = trWithValue[i].innerText;
    sum += parseInt(value);
    if (sumNumbers == null) {
      sumNumbers = value;
    } else {
      sumNumbers = sumNumbers + " + " + value;
    }
  }
  if (null != sumNumbers) {
    document.querySelector(answerBox.query.result).innerText =
      sumNumbers + " = " + sum;
  } else {
    document.querySelector(answerBox.query.result).innerText ="0"
  }
};

const isEmptyValue = function (value) {
  if (value.length == 0) {
    return true;
  } else false;
};
