import {
  global,
  getCurrentPage,
  pagelist,
  notification,
  startsAnimation,
  setNavStars,
  setFooterStars,
  initNavbar,
  setNavMenuSelected,
  initDropdown,
  initPreloader,
  showPreloader,
  hidePreloader,
} from "./utilities/global";
import { landing } from "./view/landing/_index";

import {run as binary_bridge} from "./view/binary-bridge/binary-bridge";

const NOTIFICATION_TIMEOUT = 3000;
const DROPDOWN_TIMEOUT = -1; //> -1 to not set
const PRELOADER_TIMEOUT = 5000;

window.addEventListener("load", (e) => {
  global();
  const currentPage = getCurrentPage();
  const pages = pagelist();
  notification(NOTIFICATION_TIMEOUT);
  startsAnimation();

  const initPage = function () {
    console.log("Current page: " + currentPage);
    switch (currentPage) {
      case pages.LANDING:
        landing();
        // console.log('loading landing page js')
        break;
      case pages.DASHBOARD:
        setNavStars();
        setFooterStars();
        initNavbar();
        setNavMenuSelected(pages.DASHBOARD);
        break;
      case pages.APPS:
        setNavStars();
        setFooterStars();
        initNavbar();
        initDropdown(DROPDOWN_TIMEOUT);
        break;
      case pages.BINARY_BRIDGE:
        console.log('init page : '+pages.BINARY_BRIDGE);
        setNavStars();
        setFooterStars();
        initNavbar();
        binary_bridge();
        // initInputMode();
        break;
      default:
        console.log("no page found!", pages);
    }
  };
  initPage();
  initPreloader(PRELOADER_TIMEOUT);
  hidePreloader();
});
