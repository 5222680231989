import { tabs as runTabs, initInputMode as runInputMode } from "./tabs";
import { run as runConvert , hideAllResult } from "./convert";

export const run = () => {
  hideDefault();
  runTabs();
  runInputMode();
  runConvert();
};


const hideDefault = ()=>{
   hideAllResult();
}
