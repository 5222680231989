// const { JSDOM } = require( "jsdom" );
// const { window } = new JSDOM( "" );
// const $ = require( "jquery" )( window );

const PAGE_LANDING = "landing";
const PAGE_DASHBOARD = "dashboard";
const PAGE_APPS = "apps";
const PAGE_BINARY_BRIDGE = "binary-bridge";

export function global() {
  var originalFetch = window.fetch;
  window.fetch = function (url, options) {
    console.log("Fetch intercepted. URL:", url);
    showPreloader();
    return originalFetch.apply(this, arguments);
  };
}

export function getCurrentPage() {
  return document.querySelector("body").classList[0];
}

export function pagelist() {
  pages = {
    LANDING: PAGE_LANDING,
    DASHBOARD: PAGE_DASHBOARD,
    APPS: PAGE_APPS,
    BINARY_BRIDGE: PAGE_BINARY_BRIDGE,
  };

  return pages;
}

export function notification(seconds) {
  (document.querySelectorAll(".notification .delete") || []).forEach(
    ($delete) => {
      const $notification = $delete.parentNode;

      $delete.addEventListener("click", () => {
        $notification.parentNode.removeChild($notification);
        // setTimeout(() => {
        //   $notification.parentNode.removeChild($notification);
        //   console.log("after " + seconds / 1000 + "s");
        //   const notification = document.querySelector(".notification");
        // }, seconds);
      });
    }
  );
}

export function startsAnimation() {
  const starsContainer = document.querySelectorAll(".stars");
  const numberOfStars = 100;
  loadStars(starsContainer, numberOfStars);

  console.log("loading starts....");
}

export const setNavStars = function () {
  const starsContainer = document.querySelectorAll(".starsNav");
  const numberOfStars = 20;
  loadStars(starsContainer, numberOfStars);

  console.log("loading starts for nav....");
};
export const setFooterStars = function () {
  const starsContainer = document.querySelectorAll(".starsFooter");
  const numberOfStars = 50;
  loadStars(starsContainer, numberOfStars);

  console.log("loading starts for footer....");
};

const loadStars = function (starsContainer, numberOfStars) {
  starsContainer.forEach((starsContainer) => {
    for (let i = 0; i < numberOfStars; i++) {
      const star = document.createElement("div");
      star.className = "star";
      star.style.left = `${Math.random() * 100}%`;
      star.style.top = `${Math.random() * 100}%`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      star.style.animationDuration = `${Math.random() * 3 + 2}s`;
      star.style.animation = `twinkle ${star.style.animationDuration} infinite`;
      starsContainer.appendChild(star);
    }
  });
};

export const initNavbar = function () {
  // Check for click events on the navbar burger icon
  $(".navbar-burger").on("click", function () {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
  });
};

export const setNavMenuSelected = function (page = null) {
  switch (page) {
    case PAGE_DASHBOARD:
      console.log("page in = " + page);
      // console.log($('.navbar-start>.navbar-item:nth-child(1)') );
      $(".navbar-start>.navbar-item:nth-child(1)").addClass("is-selected");
      break;
  }
};

export const initDropdown = function (timeout) {
  const dropdownsList = document.querySelectorAll(".dropdown ");

  console.log("dropdowns list", dropdownsList);

  dropdownsList.forEach((dropdown) => {
    dropdown.addEventListener("click", (ev) => {
      ev.preventDefault();
      console.log("dropdown cliked", dropdown);

      dropdown.classList.toggle("is-active");

      //> un select active dropdown if present

      // if (dropdown.classList.contains('is-active')) {
      //   dropdown.classList.remove("is-active");
      // }else{
      //   dropdown.classList.add("is-active");
      // }

      if (timeout != -1) {
        setTimeout(() => {
          dropdown.classList.toggle("is-active");
        }, timeout);
      }
    });
  });
};

export const spaceAvatar = function () {
  const svg = document.querySelector("svg");
  const wave = svg.querySelector("#wave");
  const popup = svg.querySelector("#showPopup");
  const popupText = svg.querySelector("#popupText");

  function showMessage(message) {
    popupText.textContent = message;
    wave.beginElement();
    popup.beginElement();
  }

  // Example usage:
  svg.addEventListener("click", () => showMessage("Hi, welcome!"));

  // To change the message programmatically:
  // showMessage('Any custom message here!');
};

export const initPreloader = function (duration) {
  console.log("initPreloader");
  // gsap.fromTo(".preloader", { opacity: 1 }, { opacity: 2, duration: duration });
  setTimeout(() => {
    $(".preloader > .box.is-error").show();
  }, duration);
};

export const showPreloader = function () {
  $(".preloader").show();
  $(".preloader > .box.is-error").hide();
  $("#main").hide();
};

export const hidePreloader = function () {
  $(".preloader").hide();
  $(".preloader > .box.is-error").hide();
  $("#main").show();
};
