//> this file is resposible to manage convert functionality in binary-bridge

import { convert as convert_binary_to_decimal } from "./convert/binary_to_decimal";

//> for Main result div parent

// export const RESULT_MESSAGE_BOX_ID = "results";
export const RESULT_MESSAGE_BOX_ID = {
  result: "results",
  binary_to_decimal: "binary_to_decimal",
};

const showResultBoxWith = (box_id) => {
  showResultBox();
  $("#" + box_id).show();
};

const hideResultBoxWith = (box_id) => {
  hideResultBox();
  $("#" + box_id).hide();
};

const showResultBox = () => {
  $("#" + RESULT_MESSAGE_BOX_ID.result).show();
};
const hideResultBox = () => {
  $("#" + RESULT_MESSAGE_BOX_ID.result).hide();
};

export const hideAllResult = () => {
  hideResultBox();
  $("#" + RESULT_MESSAGE_BOX_ID.binary_to_decimal).hide();
};

export const run = function () {
  const CONVERT_BTN_ID = "calculateConvertBtn";

  const INPUT_FIELD_ID = "input-mode";

  //> for dropbox {from} and {to} variables

  const dropboxSelect = {
    from: {
      _id: "fromSelect",
    },
    to: {
      _id: "toSelect",
    },
  };

  //< end for dropbox {from} and {to} variables

  //> list of convertion type

  const conversionType = {
    binary: {
      decimal: "binary:decimal",
      octal: "binary:octal",
      hexadecimal: "binary:hexadecimal",
    },
    decimal: {
      binary: "decimal:binary",
      octal: "decimal:octal",
      hexadecimal: "decimal:hexadecimal",
    },
    octal: {
      binary: "octal:binary",
      decimal: "octal:decimal",
      hexadecimal: "octal:hexadecimal",
    },
    hexadecimal: {
      binary: "hexadecimal:binary",
      decimal: "hexadecimal:decimal",
      octal: "hexadecimal:octal",
    },
  };

  //< end list of convertion type

  //////////////////////////> convert button on click event

  document.getElementById(CONVERT_BTN_ID).addEventListener("click", (ev) => {
    const value = getInputValue();
    const from = document.getElementById(dropboxSelect.from._id).value;
    const to = document.getElementById(dropboxSelect.to._id).value;
    const converion = from + ":" + to;
    console.log("from : " + from + " to: " + to);
    hideAllResult();

    switch (converion) {
      case conversionType.binary.decimal: {
        const show = convert_binary_to_decimal(value);
        if (show) {
          showResultBoxWith(RESULT_MESSAGE_BOX_ID.binary_to_decimal);
        }
        break;
      }
      default: {
        alert("Operation not yet defined , try again later!");
      }
    }
  });

  //////////////////////////< end convert button on click event

  //> get input value
  const getInputValue = function () {
    return document.getElementById(INPUT_FIELD_ID).value;
  };
  //< end get input value

  //!end of run
};
